import React, { useEffect, useState } from "react";
import { PropsFAQ, PropsFAQCategory } from "./types";
import { useQuery } from "react-query";
import axios from "axios";
import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import TextDisplay from "../../common/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faA, faComment, faMessageCheck, faMessageQuestion, faMinus, faPlus, faQ, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

const FAQs = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { isLoading, data } = useQuery(`faqs-`, () => axios.get(`${urls.remoteURL}get-faqs`));

    const [faqs, setFAQs] = useState<PropsFAQCategory[]>([])

    useEffect(() => {
        if (!isLoading && data?.data) {
            const rows: PropsFAQCategory[] = [];
            for (const row of data?.data.categories) {
                rows.push({
                    ...row,
                    faqs: data?.data.faqs.filter((x: PropsFAQ) => x.category_id === row.id),
                })
            }
            (setFAQs(rows));
        }
    }, [data?.data])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between align-top" >
                {MenuElement}
                <div className="flex gap-x-2 align-top">
                    <Link
                        to={'..'}
                        className="btn btn-green uppercase rounded-full px-6 gap-2"
                    >
                        Ask a Question
                        <FontAwesomeIcon icon={faComment} fixedWidth />
                    </Link>
                </div>
            </div>
            <div className="md:grid grid-cols-2 gap-4 space-y-4 md:space-y-0">
                {faqs.length > 0 ?
                    <>
                        {faqs.map((faq: PropsFAQCategory) =>
                            <div key={`faq-${faq.id}`} className="card p-5">
                                <h2 className="border-b mb-2">{faq.name}</h2>
                                {faq.faqs?.map(q => <>
                                    <Disclosure key={faq.id + '-' + q.id} as="div" className="pt-6">
                                        {({ open }) => (
                                            <>
                                                <dt>
                                                    <Disclosure.Button className="group flex w-full items-start justify-between text-left text-gray-900">
                                                        <div className="whitespace-normal text-base flex items-center gap-2 font-semibold">
                                                            <FontAwesomeIcon icon={faMessageQuestion} className="text-gray-600" /><TextDisplay text={q.question as string} className="font-bold" />
                                                        </div>
                                                        <div className="ml-6 flex h-7 items-center">
                                                            <FontAwesomeIcon icon={open ? faMinus : faPlus} aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                                                            {/* <FontAwesomeIcon icon={faMinus} aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" /> */}
                                                        </div>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="dd" className="mt-2 pr-12 flex gap-2">
                                                    <FontAwesomeIcon icon={faMessageCheck} className="text-gray-600" /><TextDisplay text={q.answer as string} />
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>


                                    {/* <div className="space-y-2">
                                        <FontAwesomeIcon icon={faMessageQuestion} className="text-gray-600" /><TextDisplay text={q.question as string} className="font-bold" />
                                        <FontAwesomeIcon icon={faMessageCheck} className="text-gray-600" /><TextDisplay text={q.answer as string} />

                                    </div> */}
                                </>)}
                                {/* <TextDisplay text={faq.answer as string} /> */}
                            </div>
                        )}
                    </>
                    :


                    <h1>No FAQs?</h1>
                }
            </div>
        </>
    )
}

export default FAQs;